<template>
    <div class="tgcss-max-w-[1200px] tgcss-m-auto tgcss-w-full tgcss-relative">

        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow tgcss-mb-6">قیمت ارزهای دیجیتال | کیف پول</div>


        <div class="tgcss-grid tgcss-gap-4 tgcss-grid-cols-3  tgcss-mb-5">
            <div class="card">
                <div class="card-body">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-6">
                        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">دارایی‌ها</div>
                        <div class="tgcss-flex tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer">
                            <div class="tgcss-rounded-md tgcss-bg-white tgcss-p-0.5 tgcss-px-2 tgcss-pb-1">تومان</div>
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">USDT</div>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-sm tgcss-text-gray-700">کیف‌پول : </div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center">
                            <span class="tgcss-text-base">174,794,064</span>
                            <span class="tgcss-mr-1 tgcss-text-xs tgcss-opacity-70">تومان</span>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-mt-1 tgcss-mb-4">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-xss tgcss-text-gray-700 tgcss-opacity-80">تغییر در ۲۴ ساعت</div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center tgcss-text-red-400">
                            <span class="tgcss-text-xss">1,774,035-</span>
                            <span class="tgcss-mr-1 tgcss-text-xss tgcss-opacity-70">تومان</span>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-start tgcss-h-[74px]">
                        <img src="https://api.wallex.ir/coins/BTC/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <img src="https://api.wallex.ir/coins/TRX/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <img src="https://api.wallex.ir/coins/USDT/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <img src="https://api.wallex.ir/coins/BNB/icon/svg" class="tgcss-h-7 tgcss-w-7 tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full" alt="avatar">
                        <span class="tgcss-bg-gray-100 tgcss-text-gray-600 tgcss-flex tgcss-items-center tgcss-justify-center tgcss-h-7 tgcss-w-7 tgcss-font-semibold tgcss-flex-none tgcss-ml-1.5 tgcss-rounded-full">+12</span>
                    </div>
                    <div class="tgcss-w-full tgcss-grid tgcss-gap-3 tgcss-grid-cols-2 tgcss-font-semibold tgcss-text-sm tgcss-mt-5">
                        <router-link :to="{ name: 'ExchangeWalletDeposit' }" :data-target="'ExchangeWalletDeposit'"  class="!tgcss-bg-[#f8931d] !tgcss-text-center !tgcss-no-underline !tgcss-text-white tgcss-font-bold !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md">
                            واریز
                        </router-link>
                        <router-link :to="{ name: 'ExchangeWalletWithdraw' }" :data-target="'ExchangeWalletWithdraw'"  class="!tgcss-bg-[#f4f5f7] !tgcss-text-center !tgcss-no-underline hover:!tgcss-bg-[#f8931d] !tgcss-text-gray-700 !tgcss-font-semibold hover:!tgcss-text-white !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md">
                            برداشت
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="card tgcss-col-span-2 ">
                <div class="card-body">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-6">
                        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">کارمزد معامله‌ها</div>
                        <div class="tgcss-flex tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer">
                            <div class="tgcss-rounded-md tgcss-bg-white tgcss-p-0.5 tgcss-px-2 tgcss-pb-1">تومان</div>
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">USDT</div>
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">BTC</div>
                        </div>
                    </div>
                    <div class="tgcss-bg-gray-100 tgcss-rounded-md tgcss-pt-[14px] tgcss-pb-[15px] tgcss-px-4 tgcss-mb-11">
                        <div class="tgcss-flex tgcss-items-center tgcss-font-semibold">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-xs tgcss-text-gray-700">میزان کارمزد شما : </div>
                        <div class="tgcss-font-semibold tgcss-text-xs tgcss-flex tgcss-items-center" dir="ltr">
                            %0.2
                        </div>
                    </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-sm tgcss-text-gray-700">حجم معاملات ۳۰ روزه شما : </div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center">
                            <div class="tgcss-text-base">14,794,064</div>
                            <div class="tgcss-mr-1 tgcss-text-xs tgcss-opacity-70">تومان</div>
                        </div>
                    </div>
                    <div>
                        <div class="tgcss-flex tgcss-items-center tgcss-justify-between tgcss-mt-3 ">
                            <div class="tgcss-text-slate-500 dark:tgcss-text-zink-200 tgcss-mb-1"> 14,794,064 تومان </div>
                            <div class="tgcss-mb-0 tgcss-text-slate-500">14.7%</div>
                        </div>
                        <div class="tgcss-w-full tgcss-bg-slate-200 tgcss-rounded-full tgcss-h-2.54 dark:tgcss-bg-zink-600">
                            <div class="tgcss-bg-[#f8931d] tgcss-h-2.5 tgcss-rounded-full" style="width: 14.7%"></div>
                        </div>
                        <div class="tgcss-text-xs tgcss-font-normal tgcss-text-slate-400 tgcss-text-justify tgcss-py-2">
                            سطح کارمزد معاملات بر اساس حجم معامله‌های ۳۰ روز گذشته شما در هر پایه بازار محاسبه می‌شود.
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="card">
           <div class="card-body !tgcss-pb-0">
                <div class="tgcss-flex tgcss-items-center tgcss-mb-4">
                    <div class="tgcss-flex-grow">
                        <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">دارایی‌شما</div>
                    </div>

                    <label class=" tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer !tgcss-hidden md:!tgcss-flex">
                        <svg class="tgcss-w-6 tgcss-mr-1" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <input type="text" v-model="search" placeholder="جستجوی بازار ..." class="!tgcss-bg-transparent !tgcss-border-none !tgcss-shadow-none ">
                    </label>
                </div>
                

                <div class="-tgcss-mx-5 tgcss-overflow-x-auto">
                    <table class="tgcss-w-full tgcss-whitespace-nowrap !tgcss-border-none !tgcss-bg-transparent">
                        <thead class="ltr:tgcss-text-left rtl:tgcss-text-right">
                            <tr>
                                <th class="tgcss-px-4 tgcss-py-3 tgcss-pr-6 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">نام کوین</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">موجودی کل</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden sm:tgcss-table-cell">فریز شده</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">در دسترس</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">ارزش تخمینی</th>
                                <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">سود / ضرر (24h)</th>
                                <th class="tgcss-px-4 tgcss-py-3 tgcss-pl-[20px]  tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 text-center tgcss-hidden md:tgcss-table-cell">عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in filteredItems" v-bind:key="index" class="hover:tgcss-bg-slate-100 tgcss-group">
                                <td class="tgcss-px-3.5 tgcss-py-3 tgcss-pr-[18px] tgcss-border-y tgcss-border-gray-200 ">
                                    <div class="tgcss-flex tgcss-gap-2 tgcss-items-center">
                                        <div class="tgcss-bg-green-100 tgcss-rounded-full tgcss-size-7 tgcss-shrink-0">

                                            <img v-if="item.symbol != 'Rial'" :src="'https://api.wallex.ir/coins/'+item.symbol+'/icon/svg'" alt="" class="tgcss-h-7 tgcss-rounded-full">
                                            <img v-else src="https://persianwallet.com/themes/frontend/persianwallet/img/blockchain/IRR.svg" alt="" class="tgcss-h-7 tgcss-rounded-full">

                                        </div>
                                        <a href="#" class="grow !tgcss-no-underline">
                                            <h3 class="!tgcss-font-bold !tgcss-m-0 !tgcss-text-base tgcss-flex tgcss-items-center !tgcss-text-gray-700">{{item.nameFa}}</h3>
                                            <div class="tgcss-text-gray-500 tgcss-font-medium tgcss-text-xss tgcss-mb-1">{{item.symbol}}</div>
                                        </a>
                                    </div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700">
                                    <div class="">{{item.deposit}}</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.symbol}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden sm:tgcss-table-cell">
                                    <div class="">-</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.symbol}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden md:tgcss-table-cell">
                                    <div class="">{{item.deposit}}</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.symbol}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden md:tgcss-table-cell">
                                    <div class="">{{item.changeTotalFa}}</div>
                                    <div class="tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.pTypeFa}}</div>
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden sm:tgcss-table-cell">
                                    <div class="">{{item.changeTotal}} <div class="tgcss-inline-block tgcss-font-medium tgcss-text-xs tgcss-opacity-50">{{item.pTypeFa}}</div></div>
                                    <div class="tgcss-inline-flex tgcss-items-center  tgcss-font-semibold text-right" :class="item.changeLevel == 'high' ? 'tgcss-text-green-500' : 'tgcss-text-red-500'"  dir="ltr">
                                        <svg class="tgcss-w-4 tgcss-mr-1" :class="item.changeLevel == 'low' ? ' tgcss-rotate-180 ' : ''"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m4.5 15.75 7.5-7.5 7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        {{item.change}}

                                    </div>   
                                </td>
                                <td class="tgcss-px-3.5 tgcss-py-2.5 tgcss-text-sm tgcss-text-gray-700 tgcss-font-semibold text-left tgcss-hidden md:tgcss-table-cell">
                                    <button v-if="item.actions.trade" class="tgcss-bg-[#e2e7f1]  hover:tgcss-bg-[#f8931d]  tgcss-text-gray-700 hover:tgcss-text-white  tgcss-font-bold tgcss-pt-2 tgcss-pb-3 tgcss-px-4 !tgcss-rounded-md"> معامله </button>

                                    <router-link :to="{ name: 'ExchangeWalletDeposit' }" :data-target="'ExchangeWalletDeposit'"  class="!tgcss-bg-[#f4f5f7] !tgcss-mr-2 !tgcss-text-center !tgcss-no-underline hover:!tgcss-bg-[#f8931d] !tgcss-text-gray-700 !tgcss-font-semibold hover:!tgcss-text-white !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md">
                                        واریز
                                    </router-link>
                                    <router-link :to="{ name: 'ExchangeWalletWithdraw' }" :data-target="'ExchangeWalletWithdraw'" class="!tgcss-bg-[#f8931d] !tgcss-mr-2 !tgcss-text-center !tgcss-no-underline !tgcss-text-white tgcss-font-bold !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md"  >
                                        برداشت
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>

    </div>
</template>


<script>

export default {
    name: 'CryptoWallet',
    components: {},
    data: function () {
        return {
            search:'',
            List:[
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"ریال",
                    "symbol":"Rial",
                    "pType":"Rial",
                    "pTypeFa":"ریال",
                    "deposit": "450,000",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"32,500",
                    "changeTotalFa":"450,000",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":false,
                    }
                },
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"بیت‌کوین",
                    "symbol":"BTC",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "0.12",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"20,794,000",
                    "changeTotalFa":"90,017,302",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"2",
                    "name":"Ethereum",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "2.7",
                    "p":"$3,495",
                    "pt":"435,000,001",
                    "changep":"253,366",
                    "change":"-2.16٪",
                    "changeLevel":"low",
                    "changeTotal":"2,420,000",
                    "changeTotalFa":"12,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"3",
                    "name":"Tether",
                    "nameFa":"تتر",
                    "symbol":"USDT",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "415",
                    "p":"$1",
                    "pt":"68,501",
                    "changep":"1,366",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"94,372",
                    "changeTotalFa":"7,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"4",
                    "name":"Arbitrum",
                    "nameFa":"آربیتروم",
                    "symbol":"ARB",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "170",
                    "p":"$1",
                    "pt":"0.11",
                    "changep":"0,06",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"4,372",
                    "changeTotalFa":"302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                }
                
                
            ],
            topItems:[
                {
                    "id":"1",
                    "tag":"بیشترین‌سود",
                    "name":"Bitcoin",
                    "nameFa":"بیت‌کوین",
                    "symbol":"BTC",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"504.3",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$2,794,372",
                    "changeTotalFa":"190,017,302,475",
                },
                {
                    "id":"2",
                    "tag":"بیشترین‌ضرر",
                    "name":"Ethereum",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$3,495",
                    "pt":"435,000,001",
                    "changep":"142.54",
                    "change":"-2.16٪",
                    "changeLevel":"low",
                    "changeTotal":"$794,372",
                    "changeTotalFa":"19,017,302,475",
                },
                {
                    "id":"3",
                    "tag":"جدیدترین",
                    "name":"Tether",
                    "nameFa":"تتر",
                    "symbol":"USDT",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$1",
                    "pt":"68,501",
                    "changep":"0.0012",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$794,372",
                    "changeTotalFa":"19,017,302,475",
                },
                {
                    "id":"4",
                    "tag":"داغ‌ترین",
                    "name":"Arbitrum",
                    "nameFa":"آربیتروم",
                    "symbol":"ARB",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$1",
                    "pt":"0.11",
                    "changep":"0.021",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$94,372",
                    "changeTotalFa":"19,017,302,475",
                }
                
            ]
        }
    },
    mounted() {
    },
    computed: {
        filteredItems() {
            return this.List.filter(item => {
                return item.nameFa.toLowerCase().includes(this.search.toLowerCase()) || 
                item.name.toLowerCase().includes(this.search.toLowerCase()) || 
                item.pType.toLowerCase().includes(this.search.toLowerCase()) || 
                item.symbol.toLowerCase().includes(this.search.toLowerCase()) 
            })
        }
    },
    methods: {
    }
}

</script>
    